import ApiService from "@/core/services/api.service";

const NewBusinessDataService = {
  serverUrl: `${window.location.origin}/services/new-business`,
  transactionId: null,
  userId: null,

  setServerUrl: function (url) {
    this.serverUrl = url;
  },

  init: function (transactionId, userId, token) {
    if (process.env.NODE_ENV === "development") {
      this.serverUrl = `http://localhost:3000/services/new-business`;
    }

    this.transactionId = transactionId;
    this.userId = userId;
    ApiService.saveTokenAndSetHeader(token);
  },

  getWizardAppData: function () {
    return ApiService.get(
      `${this.serverUrl}/${this.transactionId}/wizard-app-data`,
      {
        params: { userId: this.userId },
      }
    );
  },

  saveWizardAppData: function (wizardAppData) {
    return ApiService.post(
      `${this.serverUrl}/${this.transactionId}/wizard-app-data`,
      {
        appData: wizardAppData,
        userId: this.userId,
      }
    );
  },

  getReferenceData: function () {
    return ApiService.get(`${this.serverUrl}/${this.transactionId}/ref-data`, {
      params: { userId: this.userId },
    });
  },

  createDeclarationForm: function () {
    return ApiService.post(
      `${this.serverUrl}/${this.transactionId}/declaration-form/create`,
      {
        userId: this.userId,
      }
    );
  },

  overrideDeclarationForm: function (overrideReason) {
    return ApiService.post(
      `${this.serverUrl}/${this.transactionId}/declaration-form/override`,
      {
        userId: this.userId,
        overrideReason: overrideReason,
      }
    );
  },

  updateTransactionData: function (data) {
    return ApiService.post(`${this.serverUrl}/${this.transactionId}/update`, {
      transactionData: data,
      userId: this.userId,
    });
  },

  updateTransactionStatus: function (flagName, flagValue) {
    return ApiService.post(`${this.serverUrl}/${this.transactionId}/status`, {
      flagName: flagName,
      flagValue: flagValue,
      userId: this.userId,
    });
  },

  createQuoteSlip: function (txnToDate, insurerInstructions) {
    return ApiService.post(
      `${this.serverUrl}/${this.transactionId}/quote-slip/create`,
      {
        userId: this.userId,
        insurerInstructions: insurerInstructions,
      }
    );
  },

  saveSelectedInsurers: function (selectedInsurers) {
    return ApiService.post(
      `${this.serverUrl}/${this.transactionId}/selected-insurers`,
      {
        selectedInsurers: selectedInsurers,
        userId: this.userId,
      }
    );
  },

  getQuoteComparisonData: function () {
    return ApiService.get(
      `${this.serverUrl}/${this.transactionId}/quote-comparison`,
      {
        params: { userId: this.userId },
      }
    );
  },

  saveQuoteComparisonData: function (comparisonData) {
    return ApiService.post(
      `${this.serverUrl}/${this.transactionId}/quote-comparison`,
      {
        comparisonData: comparisonData,
        userId: this.userId,
      }
    );
  },

  getQuoteDetails: function (quoteId) {
    return ApiService.get(
      `${this.serverUrl}/${this.transactionId}/quotes/${quoteId}`,
      {
        params: { userId: this.userId },
      }
    );
  },

  saveQuoteDetails: function (quote) {
    return ApiService.post(
      `${this.serverUrl}/${this.transactionId}/quotes/${quote.id}`,
      {
        quote: quote,
        userId: this.userId,
      }
    );
  },

  applyBrokerFeeToAllQuotes: function (feesCommissionsDetails) {
    return ApiService.post(
      `${this.serverUrl}/${this.transactionId}/apply-fees-to-quotes`,
      {
        feesCommissionsDetails: feesCommissionsDetails,
        userId: this.userId,
      }
    );
  },

  setRecommendedQuote: function (quoteId) {
    return ApiService.post(
      `${this.serverUrl}/${this.transactionId}/quotes/${quoteId}/recommended`,
      {
        userId: this.userId,
      }
    );
  },

  createBrokerAdvice: function (template) {
    return ApiService.post(
      `${this.serverUrl}/${this.transactionId}/broker-advice/create`,
      {
        userId: this.userId,
        template: template,
      }
    );
  },

  getBrokerAdviceLink: function () {
    return ApiService.get(
      `${this.serverUrl}/${this.transactionId}/broker-advice/link`,
      {
        params: { userId: this.userId },
      }
    );
  },

  requestBrokerAdviceReview: function (reviewer, comments) {
    return ApiService.post(
      `${this.serverUrl}/${this.transactionId}/broker-advice/request-review`,
      {
        reviewer: reviewer,
        comments: comments,
        userId: this.userId,
      }
    );
  },

  approveOrRejectBrokerAdvice: function (status, comments) {
    return ApiService.post(
      `${this.serverUrl}/${this.transactionId}/broker-advice/approve-reject`,
      {
        status: status,
        comments: comments,
        userId: this.userId,
      }
    );
  },

  refreshBrokerAdviceStatus: function () {
    return ApiService.get(
      `${this.serverUrl}/${this.transactionId}/broker-advice/status`,
      {
        params: { userId: this.userId },
      }
    );
  },

  acceptQuote: function (quoteId) {
    return ApiService.post(
      `${this.serverUrl}/${this.transactionId}/quotes/${quoteId}/accept`,
      {
        userId: this.userId,
      }
    );
  },

  refreshQuotes: function () {
    return ApiService.get(`${this.serverUrl}/${this.transactionId}/quotes`, {
      params: { userId: this.userId },
    });
  },

  createNBLetter: function () {
    return ApiService.post(
      `${this.serverUrl}/${this.transactionId}/nb-letter/create`,
      {
        userId: this.userId,
      }
    );
  },

  saveInvoiceNumber: function (invoiceNumber) {
    return ApiService.post(`${this.serverUrl}/${this.transactionId}/invoice`, {
      invoiceNumber: invoiceNumber,
      userId: this.userId,
    });
  },

  finaliseTransaction: function () {
    return ApiService.post(`${this.serverUrl}/${this.transactionId}/finalise`, {
      userId: this.userId,
    });
  },

  reopenTransaction: function (reason) {
    return ApiService.post(`${this.serverUrl}/${this.transactionId}/reopen`, {
      userId: this.userId,
      reason: reason,
    });
  },
};

export default NewBusinessDataService;
