import Vue from "vue";
import Vuex from "vuex";

import htmlClass from "./htmlclass.module";
import config from "./config.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    htmlClass,
    config,
  },
  state: {
    wizardTitle: null,
    wizardSubtitle: null,
    transactionStatus: null,
    transactionSubStatus: null,
    transactionBoxFolderLink: null,
    crmRecordLink: null,
    nextBatchTransaction: null,
    claimLodged: false,
    claimFormViewLink: null,
    claimFormInternalLink: null,
    insurerNotified: false,
  },
  mutations: {
    setWizardTitle(state, title) {
      state.wizardTitle = title;
    },
    setWizardSubtitle(state, subtitle) {
      state.wizardSubtitle = subtitle;
    },
    setTransactionStatus(state, transactionStatus) {
      state.transactionStatus = transactionStatus;
    },
    setTransactionSubStatus(state, transactionSubStatus) {
      state.transactionSubStatus = transactionSubStatus;
    },
    setTransactionBoxFolderLink(state, txnBoxFolderLink) {
      state.transactionBoxFolderLink = txnBoxFolderLink;
    },
    setCrmRecordLink(state, crmRecordLink) {
      state.crmRecordLink = crmRecordLink;
    },
    setNextBatchTransaction(state, nextBatchTransaction) {
      state.nextBatchTransaction = nextBatchTransaction;
    },
    setClaimLodged(state, status) {
      state.claimLodged = status;
    },
    setClaimFormInternalLink(state, claimFormInternalLink) {
      state.claimFormInternalLink = claimFormInternalLink;
    },
    setClaimFormViewLink(state, link) {
      state.claimFormViewLink = link;
    },
    setInsurerNotified(state, value) {
      state.insurerNotified = value;
    },
  },

  getters: {
    isClaimLodged: state => state.claimLodged,
    isInsurerNotified: state => state.insurerNotified,
    claimFormLink: (state) => {
      return state.insurerNotified || !state.claimFormInternalLink
        ? state.claimFormViewLink
        : state.claimFormInternalLink;
    },
  }
});
